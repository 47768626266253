<template>
  <div class="httpUrl">
    <navi-gation />
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item :label="$t('System.httpUrlPage.EMMHttpUrl')" prop="httpUrl">
        <el-input v-model="ruleForm.httpUrl" size="small" style="width: 200px;"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.httpUrlPage.AssignServiceURL')" prop="allotUrl">
        <el-input v-model="ruleForm.allotUrl" size="small" style="width: 200px;"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.httpUrlPage.AssignHeartbeatAddress')" prop="heartUrl">
        <el-input v-model="ruleForm.heartUrl" size="small" style="width: 200px;"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.httpUrlPage.AssignHeartbeatPort')" prop="heartPorts">
        <el-input v-model="ruleForm.heartPorts" size="small" style="width: 200px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">{{ $t("public.Save") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import naviGation from '@/components/hearder/index'
export default {
  components: { naviGation },
  data() {
    const validateHttpUrl = (rule, value, callback) => {
      undefined
      if (value === '') {
        undefined

        callback(new Error(this.$t('System.httpUrlPage.HttpUrlVoid')))
      } else {
        undefined

        if (
          !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
            value
          )
        ) {
          undefined

          callback(new Error(this.$t('System.httpUrlPage.HttpUrlReg')))
        } else {
          undefined

          callback()
        }
      }
    }
    const validateAllotUrl = (rule, value, callback) => {
      undefined
      if (value === '') {
        undefined

        callback(new Error(this.$t('System.httpUrlPage.HttpUrlVoid')))
      } else {
        undefined

        if (
          !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
            value
          )
        ) {
          undefined

          callback(new Error(this.$t('System.httpUrlPage.HttpUrlReg')))
        } else {
          undefined

          callback()
        }
      }
    }
    // const validateHeartUrl = (rule, value, callback) => {
    //   undefined
    //   if (value === '') {
    //     undefined

    //     callback(new Error(this.$t('System.httpUrlPage.heartUrlVoid')))
    //   } else {
    //     undefined

    //     if (!/^[0-9]*$/.test(value)) {
    //       undefined

    //       callback(new Error('分配心跳地址必须是正确的心跳地址'))
    //     } else {
    //       undefined

    //       callback()
    //     }
    //   }
    // }
    return {
      ruleForm: {
        httpUrl: '', //EMM服务地址
        allotUrl: '', //分配服务URL
        heartUrl: '', //分配心跳地址
        heartPorts: '' //分配心跳端口
      },
      rules: {
        httpUrl: [
          {
            required: true,
            message: this.$t('System.httpUrlPage.HttpUrlVoid'),
            trigger: 'blur'
          },
          { validator: validateHttpUrl, trigger: 'blur' }
        ],
        allotUrl: [
          {
            required: true,
            message: this.$t('System.httpUrlPage.allotUrlVoid'),
            trigger: 'blur'
          },
          { validator: validateAllotUrl, trigger: 'blur' }
        ],
        heartUrl: [
          { required: true, message: this.$t('System.httpUrlPage.heartUrlVoid'), trigger: 'blur' }
        ],
        heartPorts: [
          { required: true, message: this.$t('System.httpUrlPage.heartPortsVoid'), trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {},
  methods: {
    //保存接口
    async save() {
      var params = {}
      const res = await this.$axios.post('', params, true)
    },
    //点击保存按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
          this.save()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.httpUrl {
  .demo-ruleForm {
    margin: 20px 0px 0px 50px;
  }
}
</style>
>>>>>>> destination
